<template>
  <div :color="!show ? color : ``">
    <div
      :style="width ? `max-width: ${width}px; min-width:0;` : ``"
      :break="show ? null : `ellipsis-${isOverflow ? clamp : !more && isOverflow ? clamp : !more && clamp ? clamp : `0`}`"
      ref="textRef"
    >
      <button
        v-if="more && isOverflow && position === 'inline'"
        float="right"
        mgl="sm"
        fsize="zoom-sm"
        @click.stop="show = !show"
      >
        <u color="xl">
          {{ show ? $t('more-view-close') : $t('more-view') }}
        </u>
        <span color="2xs" mgl="3xs">
          <v-icon :icon="show ? `mdi-chevron-up` : `mdi-chevron-down`" />
        </span>
      </button>
      <div
        v-text="text"
        display="contents"
        :break="pre ? `pre-line` : null"
      />
    </div>
    <button
      v-if="more && isOverflow && position != 'inline'"
      :halign="position"
      class="box"
      mgt="3xs"
      fsize="zoom-sm"
      color="2xl"
      colorhover="secondary"
      @click.stop="show = !show"
    >
      <u>
        {{ show ? $t('more-view-close') : `.. ${$t('more-view')}` }}
      </u>
      <span color="2xs" mgl="3xs">
        <v-icon :icon="show ? `mdi-chevron-up` : `mdi-chevron-down`" />
      </span>
    </button>
  </div>
</template>

<script lang="ts">
export default {
  name: 'global-ellipsis',
  props: {
    text: {
      type: String,
      default () {
        return ''
      }
    },
    clamp: {
      type: Number,
      default () {
        return 1
      }
    },
    position: {
      type: String,
      default () {
        return 'bottom'
      }
    },
    width: {
      type: Number,
      default () {
        return 0
      }
    },
    color: {
      type: String,
      default () {
        return ''
      }
    },
    more: {
      type: Boolean,
      default () {
        return false
      }
    },
    pre: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  setup (props) {
    const textRef: any = ref(null)
    const isOverflow: any = ref(false)
    const lineHeight: any = ref(0)
    const observer: any = ref(null)

    onMounted(() => {
      async function calculateLine () {
        const targetRef: any = textRef.value
        lineHeight.value = parseFloat(getComputedStyle(targetRef).lineHeight)
        const elementHeight = targetRef.clientHeight
        const numberOfLines = Math.round(elementHeight / lineHeight.value)
        isOverflow.value = numberOfLines > props.clamp
      }
      const targetRef = textRef.value;
      if (targetRef) {
        observer.value = new ResizeObserver(() => {
          if (lineHeight.value) {
            observer.value.disconnect()
          } else {
            calculateLine()
          }
        })
        observer.value.observe(targetRef)
      }
      calculateLine()
    })

    // 메모리 비우기
    function restore () {
      if (observer.value) {
        observer.value.disconnect()
      }
      textRef.value = null
      isOverflow.value = null
      lineHeight.value = null
      observer.value = null
    }

    onUnmounted(() => {
      restore()
    })

    return {
      textRef,
      isOverflow
    }
  },
  data() {
    return {
      show: false,
      isClamped: false
    }
  }
}
</script>